@import "../../styles/variables/media";
@import "../../styles/variables/spaces";
@import "../../styles/variables/colors";

.prices {
  composes: section from "../../styles/grid.scss";
  @media (min-width: $large) {
    margin-bottom: 0;
  }
}

.description {
  composes: description from "../Advantages/index.module.scss";
  padding-bottom: $space-l;
}

.descriptionSmall {
  font-size: 13px;

  @media (min-width: $large) {
    font-size: 16px;
  }
}

.priceList {
  border: 1px solid $darkColor;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: $space-xl;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: $large) {
    display: grid;
    grid-gap: 1px;
    background: $darkColor;
  }

  & li:nth-child(odd) {
    & .container, p {
      background: #0e0e0e;
    }
  }
}

.listHeader {
  border-bottom: 1px solid $darkColor;
  @media (min-width: $large) {
    display: grid;
    grid-template-columns: 294px 1fr;
    background: $darkColor;
    grid-gap: 1px;
    align-items: center;
    border-bottom: none;
  }
}

.container {
  display: none;

  @media (min-width: $large) {
    display: grid;
    height: 100%;
    align-items: center;
    background: $black;
    padding-left: 14px;
  }
}

.innerContainer {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1px;
  background: $darkColor;

  & p:first-child {
    padding: 4px 0;
    grid-column-start: 1;
    grid-column-end: 4;
  }

  p {
    background: $black;
  }
}

.item {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom: 1px solid $darkColor;
  text-align: center;

  &:last-child {
    border-bottom: none;
  }

  @media (min-width: $large) {
    display: grid;
    grid-template-columns: 294px 1fr 1fr 1fr;
    grid-gap: 1px;
    border: none;

    & p {
      background: $black;
      padding: 14px;
    }
  }
}

.itemSmall {
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: $large) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.itemTitle {
  grid-column-start: 1;
  grid-column-end: 4;
  border-bottom: 1px solid $darkColor;
  padding: $space-s 0;

  @media (min-width: $large) {
    grid-column-start: initial;
    grid-column-end: initial;
    border-bottom: none;
    text-align: left;
  }
}

.itemDescription {
  padding: $space-s 0;

  &:nth-of-type(odd) {
    border-right: 1px solid $darkColor;
    border-left: 1px solid $darkColor;

    @media (min-width: $large) {
      border: none;
    }
  }
}
