@font-face {
  font-family: "Circe";
  src: url("./fonts/Circe-Regular.woff") format("woff"),
    url("./fonts/Circe-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Circe-ExtraBold";
  src: url("./fonts/Circe-ExtraBold.woff2") format("woff2"),
    url("./fonts/Circe-ExtraBold.woff") format("woff");
}

$defaults: "Helvetica", "Arial", "sans-serif";

$primaryFont: "Circe", $defaults;
$primaryExtraBoldFont: "Circe-ExtraBold", $defaults;
