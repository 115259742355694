@import "variables/colors";
@import "variables/media";
@import "variables/spaces";
@import "variables/radiuses";
@import "fonts";

@use "sass:map";

$typography-mobile: (
  title: 24px,
  subtitle: 20px,
  description: 15px,
  control: 15px,
  formControl: 15px,
);

$typography-desktop: (
  title: 40px,
  subtitle: 20px,
  description: 18px,
  control: 20px,
  formControl: 16px,
);

.title {
  font-family: $primaryExtraBoldFont;
  font-size: map_get($typography-mobile, title);
  color: $primaryColor;
  padding-bottom: $space-xl;

  @media (min-width: $large) {
    font-size: map_get($typography-desktop, title);
  }
}

.subtitle {
  font-family: $primaryFont;
  font-size: map_get($typography-mobile, subtitle);
  color: $primaryColor;

  @media (min-width: $large) {
    font-size: map_get($typography-desktop, subtitle);
  }
}

.description {
  font-family: $primaryFont;
  font-size: map_get($typography-mobile, description);
  color: $thirdlyColor;
  line-height: 20px;

  @media (min-width: $large) {
    font-size: map_get($typography-desktop, description);
    line-height: 28px;
  }
}

.accent-description {
  color: $primaryColor;
  composes: description;
  font-weight: bold;
}

.control {
  font-family: $primaryFont;
  font-size: map_get($typography-mobile, control);
  color: $primaryColor;
  background-color: $buttonColor;
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100%;
  padding: $space-s $space-m;
  border-radius: $control-radius;
  border: none;

  @media (min-width: $large) {
    font-size: map_get($typography-desktop, control);
    padding: 14px 32px;
    border-radius: $control-radius-large;
  }
}

.formControl {
  font-family: $primaryFont;
  font-size: map_get($typography-mobile, formControl);
  color: $primaryColor;

  @media (min-width: $large) {
    font-size: map_get($typography-desktop, formControl);
  }
}
