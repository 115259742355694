@import "./variables/spaces";
@import "./variables/media";

.section {
  padding: 0 $space-m;
  max-width: 320px;
  margin: 0 auto $space-3xl;

  @media (min-width: $large) {
    max-width: 1010px;
    margin-bottom: 160px;
  }
}
