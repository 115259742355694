$primaryColor: rgba(255, 255, 255, 1);
$secondaryColor: rgba(255, 255, 255, 0.8);
$thirdlyColor: rgba(255, 255, 255, 0.6);
$fourthlyColor: rgba(255, 255, 255, 0.4);

$darkColor: #1f1f1f;
$black: rgba(0, 0, 0, 1);
$grayDark: #2B2B2B;
$gray: #333333;
$modals: rgba(0, 0, 0, 0.6);

$accentColor: #00c2ff;
$errorColor: #ff5555;

$buttonColor: #483d8b;
